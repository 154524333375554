const config = {
    host: (process.env.NODE_ENV === 'production') ? 'https://liftmeup.com.mx' : 'http://192.168.100.106:3043',
    homePage: (process.env.NODE_ENV === 'production') ? 'https://liftmeup.com.mx' : 'http://192.168.100.106:3043',
    api: 'api/',
    apiSocket: 'liftmeup::1.2',
    nameDir: 'liftmeup',
    package: 'mx.com.liftmeup.web',
    version: '1.0.0',
    googleAPIKey: 'AIzaSyAzslOliof1QJX21ddCXJhR8xSzL-jUHQM',
    appName: 'LiftMeUp',
    provider: '',
    colorPrimary: '#000000',
    colorDark: '#3DD125',
    colorAccent: '#ccc',
    colorTextPrimary: '#fff',
    colorTextDark: '#000',
    playStorePassengerId: 'mx.com.liftmeup.passenger',
    playStoreDriverId: 'mx.com.liftmeup.driver',
    appStorePassengerId: '6503717376',
    appStoreDriverId: '6503717438',
    email: "Liftmeupsuport@gmail.com",
};
export default config;
